import React from 'react'
import { useHistory } from 'react-router-dom'
import { MicroAppPage } from '@kakao/mfa-container'

const CruxAdAccount = () => {
  const history = useHistory()
  const microAppManifestUrl = process.env.REACT_APP_CRUX_ACCOUNT_MICRO_HOST
  const loginToken = sessionStorage.getItem('loginToken')
  const accessToken = sessionStorage.getItem('accessToken')
  return (
    <div style={{ margin: '-60px -15px -76px' }}>
      <MicroAppPage
        history={history}
        microAppName="AdAccountApp"
        microAppManifestUrl={microAppManifestUrl}
        manifestName="asset-manifest.json"
        cssInShadowDOM={true}
        adPlatformType="KEYWORDAD"
        loginToken={loginToken}
        accessToken={accessToken}
      />
    </div>
  )
}
export default CruxAdAccount
